import React from "react";
import { faBriefcase } from "@fortawesome/free-solid-svg-icons";

import Card from "../common/card";

import "./styles/works.css";
import INFO from "../../data/user"

const Works = () => {
	const works = INFO.works;
	return (
		<div className="works">
			<Card
				icon={faBriefcase}
				title="Work"
				body={
					<div className="works-body">
						{works.map( work => (
						<div className="work">
							<img
								src={work.logo}
								alt={work.title}
								className="work-image"
							/>
							<div className="work-second-col">
								<div className="work-title">{work.title}</div>
								<div className="sub-duration-row">
									<div className="work-subtitle">
									{work.subtitle}
									</div>
									<div className="work-duration">{work.duration}</div>
								</div>
							</div>
						</div>
						)
						)}
					</div>
				}
			/>
		</div>
	);
};

export default Works;
