const INFO = {
	main: {
		title: "Weru's Portfolio",
		name: "Waweru Kamau",
		email: "wawerumail@gmail.com",
		logo: "../logos/profilelogo.png",
	},

	socials: {
		twitter: "https://twitter.com/waweru_wa_kamau",
		github: "https://github.com/waweru-kamau",
		linkedin: "https://www.linkedin.com/in/waweru-kamau/",
		instagram: "https://www.instagram.com/waweru_wa_kamau/",
		stackoverflow: "https://stackoverflow.com/users/4031108/waweru-kamau",
	},

	homepage: {
		title: "Mobile App Developer",
		description:
			"I am a software engineer with expertise in mobile apps. I have experience in building scalable, secure and reliable mobile applications using react native and other technologies. I enjoy solving complex problems and learning new skills. I am passionate about creating high-quality code that follows best practices and industry standards. I am always looking for new challenges and opportunities to grow as a developer.",
	},

	about: {
		title: "I'm Waweru, living in Nairobi where I design the future",
		description:
			"With more than 5 years of hands-on experience across a multitude of projects, I've honed a robust skill set in frontend engineering. My focus is on mobile development, mastering both iOS and Android platforms using React Native. Proficient at a senior level, I command languages like JavaScript and Typescript, alongside frameworks such as React JS and React Native. My expertise extends to technologies like GraphQL and Redux. Beyond coding, I bring a solid understanding of performance optimization, code efficiency, security practices, and the art of crafting pixel-perfect, user-friendly interfaces."
	},


	projects: [
		{
			title: "Aily Labs",
			description:
				"Redesigned existing app to a data driven SaaS, leading to easy client integration",
			logo: "./logos/ailylabs.png",
			linkText: "View Project",
			link: "https://apps.apple.com/us/app/aily/id6447188213",
		},
		{
			title: "Faida POS",
			description:
				"Developed a robust point-of-sale mobile app for small and medium enterprises",
			logo: "./logos/faida.png",
			linkText: "View Project",
			link: "https://play.google.com/store/apps/details?id=com.faida.posapp",
		},
		{
			title: "Quantrica, Athena",
			description:
				"Developed a mobile app that streamlined data analytics for Business Intelligence (B.I.)",
			logo: "./logos/athena.png",
			linkText: "View Project",
			link: "https://www.quantrica.com/",
		},
		{
			title: "Markiti, Keep It Cool Technologies",
			description:
				"Built a fresh fish delivery app linking fishermen, riders and restaurants",
			logo: "./logos/markitiapp.png",
			linkText: "View Project",
			link: "https://play.google.com/store/apps/details?id=com.markiti",
		},
	],
	works: [
		{
			title: "The Loop Co, Coimbra, Portugal (remote)",
			subtitle: "Mobile Engineer",
			logo: "./logos/theloop.png",
			duration: "Oct 2021 - Nov 2023"
		},
		{
			title: "Keep It Cool Technologies, Nairobi, Kenya",
			subtitle: "Mobile App Developer",
			logo: "./logos/markitiapp.png",
			duration: "Mar 2020 - Aug 2020"
		},
		{
			title: "Quantrica, Nairobi, Kenya",
			subtitle: "Front End Software Engineer",
			logo: "./logos/quantrica.png",
			duration: "Mar 2019 - Oct 2020"
		},
		{
			title: "Wingubox Ltd, Nairobi, Kenya",
			subtitle: "Intern / Software Engineer",
			logo: "./logos/wingubox.png",
			duration: "Jan 2017 - Nov 2018"
		}
	]
};

export default INFO;
