const SEO = [
	{
		page: "home",
		description:
			"I am a mobile developer with expertise in React Native. I have experience in building scalable, secure and reliable web and mobile applications using various frameworks and technologies.",
		keywords: ["Waweru Kamau", "Waweru K", "Waweru_wa_Kamau", "Waweru", "Kamau", "Wawerukamau"],
	},

	{
		page: "about",
		description:
			"I am a mobile developer with expertise in React Native. I have experience in building scalable, secure and reliable web and mobile applications using various frameworks and technologies.",
		keywords: ["Waweru Kamau", "Waweru K", "Waweru_wa_Kamau", "Waweru", "Kamau", "Wawerukamau"],
	},

	{
		page: "projects",
		description:
			"I've worked on a variety of projects over the years and I'm proud of the progress I've made. Many of these projects are open-source and available for others to explore and contribute to.",
		keywords: ["Waweru Kamau", "Waweru K", "Waweru_wa_Kamau", "Waweru", "Kamau", "Wawerukamau"],
	},

	{
		page: "contact",
		description:
			"If you're interested in collaborating on a project, feel free to reach out to me. I'm always open to new ideas and opportunities.",
		keywords: ["Waweru Kamau", "Waweru K", "Waweru_wa_Kamau", "Waweru", "Kamau", "Wawerukamau"],
	},
];

export default SEO;
